/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, tchat illimité',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  extCode: 'BE',
  key: 1,
  newsSectionUrl: null, // 'https://m.chat2poche.fr/news',
  footerText: null,
  footerLinks: [
    { value: 'CGU', href: 'https://m.weezchat.be/cgu' },
    { value: 'Mentions légales', href: 'https://m.weezchat.be/legal' },
    { value: 'Données personnelles', href: 'https://m.weezchat.be/donnees_personnelles' },
    { value: 'Cookies', href: 'https://m.weezchat.be/cookies' },
    { value: 'Mon Compte', href: 'https://m.weezchat.be/mon_compte' },
  ],
  LP: 'https://promo.weezchat.be/',
  redirectIfNoIframe: null,
  useBothSignUpMethod: false,
  avatarSize: 1200,
  gtm: 'GTM-PHD7MZX',
  showAllCities: false,
  completeProfileInputs: ['email'],
  RTL: false,
  unsubscribeLink: 'https://m.weezchat.be/cgu',
freeSubscription : false,
  home: {
    app: {
      available: false,
      linkGoogle: '',
      linkApple: '',
    },
    facebook: {
      available: true,
      link: 'https://www.facebook.com/WeezchatBE'
    },
    twitter: {
      available: false,
      link: ''
    },
    instagram: {
      available: true,
      link: 'https://www.instagram.com/weezchatbe/'
    }
  },
  RGPD : {
    cookies: {
      available: true,
      link: 'https://m.weezchat.be/cookies'
    }
  }
};
